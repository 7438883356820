<template>
<Form ref="form" class="form-area" :model="form" label-position="top" style="width: 500px; margin: 20px;">
  <FormItem prop="appName" label="应用名称">
    <div style="display: flex;align-items:center;">
      <Input type="text" maxlength="20" v-model="form.appName" placeholder="请输入名称"></Input>
    </div>
  </FormItem>
  <FormItem prop="area" label="应用地图中心点">
    <div style="display: flex;align-items:center;">
      <a href="#" slot="extra" @click.prevent="setMapCenter" style="margin-right:10px">
        <Icon type="ios-loop-strong"></Icon>
        设置
      </a>
      {{form.area}} - {{form.lat}} - {{form.lng}}
      <ModalSelectMapPoint v-model="showMapModal" :custCenter="{area: form.area, lat: form.lat, lng: form.lng}" @posSelected="posSelected" />
    </div>
  </FormItem>
  <FormItem prop="logo" label="可视化LOGO( 5M以内 图片尺寸485*30px) "><!-- 图片尺寸485*30px  -->
    <div class="logo-upload" @click="selectImg(false)">
      <img v-if="form.logo" :src="`${domains.baseURL}sys/setting/FileContent?id=${form.logo}&auth=${token}`" />
      <input :ref="`fileInput`" type="file" accept="image/png,image/gif,image/jpg,image/jpeg," @input="fileSelected($event, false)" />
    </div>
  </FormItem>
  <FormItem prop="sysLogo" label="系统LOGO( 5M以内 图片尺寸130*27px)">
    <div class="logo-upload" @click="selectImg(true)">
      <img v-if="form.sysLogo" :src="`${domains.baseURL}sys/setting/FileContent?id=${form.sysLogo}&auth=${token}`" />
      <input :ref="`fileInput1`" type="file" accept="image/png,image/gif,image/jpg,image/jpeg," @input="fileSelected($event, true)" />
    </div>
  </FormItem>
  <FormItem>
    <Button type="primary" :loading="loading" @click="saveSetting" class="mr10">保存设置</Button>
    <Button v-if="user.isSuper" type="success" :loading="loading" @click="syncOldConfig">同步旧版配置</Button>
    <!-- {{ user }} -->
  </FormItem>
  <FormItem v-if="syncReuslt.success" label="配置同步结果">
    <ul>
      <li v-for="(v, k) in syncReuslt" :key="k">{{ k }}: {{ v }}</li>
    </ul>
  </FormItem>
</Form>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
export default {
  name: 'AlarmSettingIndex',
  components:{
    ModalSelectMapPoint,
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      showMapModal: false,
      form: {
        appName: '', //应用名称
        area: '深圳市', //地图区域
        lat: 32, //地图中心点，纬度
        lng: 122, //地图中心点，经度
        logo: '', //可视化LOGO
        sysLogo: '', //系统LOGO
      },
      syncReuslt: {

      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['token', 'user']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.getList();
    // this.getStationAreaSort();
  },
  destroyed: function(){
  },
  methods: {
    syncOldConfig: function(){
      if(this.syncReuslt.success){
        this.syncReuslt.success = false;
        return;
      }
      this.loading = true;
      this.$axios.post(`sys/setting/syncOldConfig`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          res.data.success = true;
          this.$set(this, 'syncReuslt', res.data);
          this.$Modal.info({
            title: "系统提示",
            content: `数据同步成功`,
          });
        }
      });
    },
    posSelected: function (pos) {
      this.form.lat = pos.lat;
      this.form.lng = pos.lng;
      this.form.area = pos.city;
    },
    setMapCenter: function () {
      this.showMapModal = true;
    },
    selectImg: function (isSysLog) {
      if(isSysLog){
        let fs = this.$refs.fileInput1;
        if (!fs) return;
        fs.click();
        return;
      }
      let fs = this.$refs.fileInput;
      if (!fs) return;
      fs.click();
    },
    fileSelected: async function (e, isSysLog) {
      if (e.target.files.length == 0) return;
      let file = e.target.files[0];
      if (file.size / 1024 / 1024 > 5) {
        file.value = "";
        this.$Message.error("图片大小不能大于5M");
        return false;
      }
      let w=485,h=30;
      if(isSysLog){
        w=130;
        h=27;
      }
      this.limitFileWH(w, h, file).then((res) => {
        if (res) {
       this.uploadImg(file, isSysLog)
        }
      })
    },
    limitFileWH(E_width, E_height, file) {
      const _this = this
      let imgWidth = ''
      let imgHight = ''
      const isSize = new Promise(function (resolve, reject) {
        const width = E_width
        const height = E_height
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          imgWidth = img.width
          imgHight = img.height
          let valid = Math.abs(img.width - width) < 5;
          if(valid) valid = Math.abs(img.height - height) < 5;
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return true
      }, () => {
        _this.$Message.error('上传图片的尺寸应为' + E_width + '*' + E_height + '，当前上传图片的尺寸为：' + imgWidth + '*' + imgHight)
        return false
      })
      return isSize
    },
    uploadImg: async function (file, isSysLog) {
      var formData = new FormData();
      formData.append(file.name, file);
      // let res = await this.$axios.post(`//${this.domains.trans}/sys/setting/FileSave`, formData);
      let res = await this.$axios.request({
        url: `sys/setting/FileSave`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      });
      if(isSysLog){
        this.$refs.fileInput1.value = '';
      }else{
        this.$refs.fileInput.value = '';
      }
      if (res.code !== 0) {
        return;
      }
      if(isSysLog){
        this.form.sysLogo = res.data;
      }else{
        this.form.logo = res.data;
      }
    },
    getList: function(){
      this.$axios.post(`sys/setting/QuerySetting`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'form', res.data);
        }
      });
    },
    saveSetting: function(){
      this.loading = true;
      this.$axios.post(`sys/setting/SaveSetting`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Modal.info({
            title: "系统提示",
            content: `保存成功`,
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.area-title{
  font-weight: bolder;
  font-size: 20px;
}
.area-list{
  display: flex;
  flex-direction: column;
}
.area-item{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.sort-title{
  width: 140px;
  text-align: right;
  padding-right: 10px;
  flex: none;
}
.logo-upload {
  width: 476px;
  height: 58px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 476px;
  height: 58px;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>